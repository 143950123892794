<template>
  <div class="content-wrapper dashboard-page">
    <h1>Dashboard Axerta</h1>
    <h2>Rilevazioni da lavorare</h2>
    <div class="d-flex align-items-start">
      <b-form-group>
        <b-form-input
          placeholder="Filtra..."
          v-model="filterText"
        ></b-form-input>
      </b-form-group>
      <b-button @click="sortByDate" class="ml-4">
        Ordina per data - {{ sortingDirection }}
      </b-button>
    </div>
    <div v-if="loading">
      <b-card>
        Caricamento in corso...
      </b-card>
    </div>
    <div v-else-if="data.length == 0">
      <b-card>
        Nessuna lavorazione da eseguire!
      </b-card>
    </div>
    <div v-else>
      <b-card
        v-for="notification in filteredData"
        :key="notification.id"
        class="notification-card mb-2"
      >
        <b-row>
          <b-col v-if="notification.url_image" cols="2"></b-col>
          <b-col :cols="notification.url_image ? 8 : 10">
            <b-row>
              <b-col class="col-12">
                <span>Dossier: </span
                ><span class="font-bold">{{ notification.NomeDossier }}</span>
              </b-col>

              <b-col class="col-12 mb-2">
                <span>Oridne di servizio: </span
                ><span class="font-bold">{{
                  notification.NomeOrdineDiServizio
                }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Cie Registrato: </span
                ><span class="font-bold">{{ notification.CieRegistrato }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Luogo della rilevazione: </span
                ><span class="font-bold">{{
                  notification.luogoRilevazione
                }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Numero di foto caricate: </span
                ><span class="font-bold">{{ notification.numeroFoto }}</span>
              </b-col>

              <b-col class="col-12 col-md-6 ">
                <span>Data: </span
                ><span class="font-bold">{{
                  new Date(notification.dataOraRilevazione).toLocaleDateString(
                    "it",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )
                }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              class="w-100"
              @click="goto(notification.idDossier)"
            >
              Apri dettaglio
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService.js";

export default {
  name: "DashboardAxerta",
  data() {
    return {
      data: [],
      loading: false,
      filterText: "",
      sortingDirection: "meno recente",
    };
  },
  mounted() {
    this.createWidget();
  },
  methods: {
    async loadBandyerSDK() {
      return await new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src =
          "https://cdn.bandyer.com/sdk/js/chat/2.5.5/bandyer-widget.min.js";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    },

    async getUserData() {
      const service = new AxiosService("UserBo/0");

      try {
        const res = await service.read();
        return res.idChat || "nessun_token_bandyer";
      } catch (err) {
        console.log(err);
      }
    },

    async createWidget() {
      // const userId = "durante_AxEmmanuele_48";
      // let userId = "Xodo_Stefano";
      console.log("CREATE WIDGET ", { userId: this.loggedUser });
      let userId = await this.getUserData();

      const appId =
        "wAppId_cc6c2fcf5d9abfb0430a4d34f7f9e0b7d1110a73cdd782296bdf2146e785";
      const region = "eu";
      const environment = "production"; //note the sandbox environment exists only in eu region
      const retrieveAccessToken = async (region, environment, userId) => {
        console.log("retrieveAccessToken", { region, environment, userId });
        const service = new AxiosService(`VideoChat/GetToken/${userId}`);
        // retrieve the access token using POST /sdk/credentials on the server side
        const accessToken = await service.read().then((res) => {
          console.log("ACCESS TOKEN", res);
          return res;
        });
        return accessToken;
      };

      try {
        await this.loadBandyerSDK();

        this.Client = window.BandyerSDK.configure({
          appId: appId,
          // userId: userId,
          region: region,
          environment: environment,
          tools: {
            chat: true,
          },
        });

        console.log("BANDYER SDK CONFIGURED", this.Client);

        this.Client.on("client:access_token:is_about_to_expire", async () => {
          const accessToken = await retrieveAccessToken(
            region,
            environment,
            userId
          );
          const { expiresAt } = await this.Client.updateAccessToken(
            accessToken
          );

          console.log("access_token:is_about_to_expire", expiresAt);
        });
        this.Client.on("client:access_token:expired", async () => {
          await this.Client.disconnect();
          const accessToken = await retrieveAccessToken(
            region,
            environment,
            userId
          );
          await this.Client.connect(userId, accessToken);
        });

        const accessToken = await retrieveAccessToken(
          region,
          environment,
          userId
        );

        await this.Client.connect(userId, accessToken);
      } catch (error) {
        console.error("Failed to retrieve the access token or connect:", error);
      } finally {
        console.log("CLIENT CONNECTED", this.Client);
      }
    },
    getData() {
      this.loading = true;
      this.data.splice(0);
      const service = new AxiosService("DossierManager/GetAttivitaDM");
      service
        .read()
        .then((response) => {
          this.data = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortByDate() {
      if (this.sortingDirection == "più recente") {
        this.data.sort(
          (a, b) =>
            new Date(b.dataOraRilevazione) - new Date(a.dataOraRilevazione)
        );
        this.sortingDirection = "meno recente";
      } else {
        this.data.sort(
          (a, b) =>
            new Date(a.dataOraRilevazione) - new Date(b.dataOraRilevazione)
        );
        this.sortingDirection = "più recente";
      }
    },
    goto(idDossier) {
      this.$router.push(`/axerta/lista-rilevazioni/${idDossier}`);
    },
  },
  computed: {
    filteredData() {
      const filter = this.filterText.toLowerCase().trim();
      return this.data.filter((notification) => {
        return (
          notification.NomeDossier.toLowerCase().includes(filter) ||
          notification.NomeOrdineDiServizio.toLowerCase().includes(filter) ||
          notification.CieRegistrato.toLowerCase().includes(filter) ||
          notification.luogoRilevazione.toLowerCase().includes(filter)
        );
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.dataOraRilevazione {
  border-left: 2px solid gold;
}
</style>
